import React, { useRef, useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import menus from "../../pages/menu";
import logoheader from "../../assets/images/logo/logo.png";
import { useMoralis, useMoralisCloudFunction } from "react-moralis";
import c from 'classnames'

const Header = () => {
  const [ showAvatarPopup, setShowAvatarPopup ] = useState(false)
  const { pathname } = useLocation();
  const { authenticate, isAuthenticated, logout, user } = useMoralis();

  const headerRef = useRef(null);
  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  });

  const isSticky = (e) => {
    const header = document.querySelector(".js-header");
    const scrollTop = window.scrollY;
    scrollTop >= 300
      ? header.classList.add("is-fixed")
      : header.classList.remove("is-fixed");
    scrollTop >= 400
      ? header.classList.add("is-small")
      : header.classList.remove("is-small");
  };

  const menuLeft = useRef(null);
  const btnToggle = useRef(null);

  const menuToggle = () => {
    menuLeft.current.classList.toggle("active");
    btnToggle.current.classList.toggle("active");
  };

  const [activeIndex, setActiveIndex] = useState(null);
  const handleOnClick = (index) => {
    setActiveIndex(index);
  };

  const login = async () => {
    try {
      const userResult = await authenticate({
        provider: "web3Auth",
	      clientId: "BMgoC2m50Eh4UmiO9MwKBHiAHOFT_P8k--ZlwzbZo6okS5MY15uKLAyufbzeqJGtAqw5Y5g2zK-POLR5Vn2NP6w",
      });

      if (userResult) {
        await authenUser()
      }
    } catch (error) {
      alert(error);
    }
  };

  const { fetch: authenUser } = useMoralisCloudFunction(
    "authenUser",
    { referrer: null },
    { autoFetch: false }
  );

  const popupToggle = () => {
    setShowAvatarPopup(!showAvatarPopup)
  }

  const popupCls = c('avatar_popup mt-20', {'visible': showAvatarPopup})

  return (
    <header id="header_main" className="header_1 js-header" ref={headerRef}>
      <div className="themesflat-container">
        <div className="row">
          <div className="col-md-12">
            <div id="site-header-inner">
              <div className="wrap-box flex">
                <div id="site-logo" className="clearfix">
                  <div id="site-logo-inner">
                    <Link to="/" rel="home" className="main-logo">
                      <img
                        className="logo-dark"
                        id="logo_header"
                        src={logoheader}
                        srcSet={`${logoheader}`}
                        alt="nft-gaming"
                      />
                      <img
                        className="logo-light"
                        id="logo_header"
                        src={logoheader}
                        srcSet={`${logoheader}`}
                        alt="nft-gaming"
                      />
                    </Link>
                  </div>
                </div>
                <div
                  className="mobile-button"
                  ref={btnToggle}
                  onClick={menuToggle}
                >
                  <span></span>
                </div>
                <nav id="main-nav" className="main-nav" ref={menuLeft}>
                  <ul id="menu-primary-menu" className="menu">
                    {menus.map((data, index) => (
                      <li
                        key={index}
                        onClick={() => handleOnClick(index)}
                        className={`menu-item ${
                          data.namesub ? "menu-item-has-children" : ""
                        } ${activeIndex === index ? "active" : ""} `}
                      >
                        <Link to={data.links}>{data.name}</Link>
                        {data.namesub && (
                          <ul className="sub-menu">
                            {data.namesub.map((submenu) => (
                              <li
                                key={submenu.id}
                                className={
                                  pathname === submenu.links
                                    ? "menu-item current-item"
                                    : "menu-item"
                                }
                              >
                                <Link to={submenu.links}>{submenu.sub}</Link>
                              </li>
                            ))}
                          </ul>
                        )}
                      </li>
                    ))}
                  </ul>
                </nav>
                <div className="flat-search-btn flex">
                  {!isAuthenticated && (
                    <div className="sc-btn-top mg-r-12" id="site-header">
                      <button
                        onClick={login}
                        className="sc-button header-slider style style-1 wallet fl-button pri-1"
                      >
                        <span>Wallet connect</span>
                      </button>
                    </div>
                  )}
                  

                  {user && <div className="" id="header_admin">
                    <div className="header_avatar">
                      <img onClick={popupToggle} className="avatar" src="https://placeimg.com/80/80/tech" alt="avatar" />
                      <div className={popupCls}>
                        <div className="d-flex align-items-center copy-text justify-content-between">
                          <span>{user.get('ethAddress')}</span>
                        </div>
                        <div className="hr"></div>
                        <div className="links mt-20">
                          <button className="mt-10" onClick={logout}>
                            <i className="fal fa-sign-out"></i>{" "}
                            <span> Logout</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
