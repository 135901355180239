import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useMoralis, useMoralisCloudFunction } from "react-moralis";

import MetamaskButton from "../assets/images/buttons/metamask.png";
import WalletConnect from "../assets/images/buttons/wallet_connect.png";

const SignUp = () => {
  const [searchParams] = useSearchParams();
  const [refValue, setRefValue] = useState(
    searchParams.get("ref") ? searchParams.get("ref") : ""
  );

  const { authenticate, isAuthenticated, logout } = useMoralis();
  const navigate = useNavigate();

  const handleRefChange = (e) => {
    setRefValue(e.target.value);
  };

  const signupWithWeb3Auth = async () => {
    if (isAuthenticated) {
      await logout()
    }
    try {
      const user = await authenticate({
        provider: "web3Auth",
        clientId:
          "BMgoC2m50Eh4UmiO9MwKBHiAHOFT_P8k--ZlwzbZo6okS5MY15uKLAyufbzeqJGtAqw5Y5g2zK-POLR5Vn2NP6w",
      })
      if (user) {
        await authenUser()
        navigate('/')
      }
      
    } catch (error) {
      alert(error);
    }
  };

  const signUp = async () => {
    //toggleModal()
    if (isAuthenticated) {
      await logout()
    }
    try {
      const user = await authenticate({
        signingMessage: "Sign Up",
      })
      if (user) {
        await authenUser()
        navigate('/')
      }
      
    } catch (error) {
      alert(error)
    }
    
  }

  const signUpMobile = async () => {
    // toggleModal()
    if (isAuthenticated) {
      await logout()
    }
    try {
      const user = await authenticate({
        signingMessage: "Sign Up",
        provider: "walletconnect", 
        mobileLinks: [
          "metamask",
        ]
      })
      if (user) {
        await authenUser()
        navigate('/')
      }
      
    } catch (error) {
      alert(error)
    }
    
  }

  const { fetch: authenUser } = useMoralisCloudFunction(
    "authenUser",
    { referrer: refValue },
    { autoFetch: false }
  );


  return (
    <div>
      <section className="tf-login tf-section">
        <div className="themesflat-container">
          <div className="row">
            <div className="col-12">
              <h2 className="tf-title-heading ct style-1">Sign Up</h2>

              <div className="flat-form box-login-email">
                <div className="box-title-login">
                  <h5>Referral Code</h5>
                </div>

                <div className="form-inner">
                  <div>
                    <input
                      onChange={handleRefChange}
                      name="refferal_code"
                      tabIndex="1"
                      aria-required="true"
                      value={refValue}
                      type="text"
                    />

                    <button onClick={signUp} className="submit" style={{marginTop: '20px'}}><img src={MetamaskButton} /></button>
                    <button onClick={signUpMobile} className="submit" style={{marginTop: '20px'}}><img src={WalletConnect} /></button>
                  </div>
                </div>
              </div>

              <div className="flat-form box-login-social" style={{marginTop: '20px'}}>
                <div className="box-title-login">
                  <h5>Sign up with social</h5>
                </div>
                <button onClick={signupWithWeb3Auth} className="sc-button style-2 fl-button pri-3">
                  <i className="icon-fl-google-2"></i>
                  <span>Sign up with Social</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default SignUp;
