import React, { useState } from "react";
import { useMoralis, useMoralisCloudFunction } from "react-moralis";
import "react-tabs/style/react-tabs.css";
import Header from "../components/header/Header";
import CardModal from "../components/layouts/CardModal";
import {CopyToClipboard} from "react-copy-to-clipboard";

import { useEffect } from "react";
import axios from "axios";
import adler32 from "adler-32";
import {
  FacebookShareButton,
  FacebookIcon,
  LineShareButton,
  LineIcon,
  TwitterShareButton,
  TwitterIcon,
  TelegramShareButton,
  TelegramIcon,
} from "react-share";

const Refer = () => {
  const [modalShow, setModalShow] = useState(false);
  const [rewards, setRewards] = useState(null);
  const [refCode, setRefCode] = useState(null);
  const [downlines, setDownlines] = useState([]);

  const { user } = useMoralis();

  useEffect(() => {
    if (user) {
      setRefCode(`cf${adler32.buf(user.get("ethAddress"))}`);
      fetchReferReward(user);
    }
  }, [user]);

  const { fetch: fetchRewards } = useMoralisCloudFunction(
    "fetchRewards",
    null,
    { autoFetch: false }
  );

  const fetchReferReward = async () => {
    try {
      const result = await fetchRewards();
      setRewards(result.rewards);
      setDownlines(result.downlines);
    } catch (error) {
      console.log(error);
    }
  };

  if (!user) return <Header />;

  return (
    <div className="authors-2">
      <Header />
      <section className="tf-section authors">
        <div className="themesflat-container">
          <div className="flat-tabs tab-authors">
            <div className="author-profile flex">
              <div className="feature-profile">
                <img
                  src={
                    refCode
                      ? `https://quickchart.io/qr?text=https://invite.crypterium.game/salepage/referral/${refCode}&centerImageUrl=https://crypterium.game/assets/img/logo-m.png&size=500&centerImageSizeRatio=0.5`
                      : ""
                  }
                  alt="Axies"
                  className="avatar"
                />
              </div>
              <div className="infor-profile">
                <span>Member Profile</span>
                <h2 className="title">{user && user.get("ethAddress")}</h2>
                <h2 className="title">{refCode ? refCode: ''}</h2>

                <form style={{width: '100%'}}>
                  <input
                    type="text"
                    className="inputcopy"
                    value={refCode ? `https://invite.crypterium.game/salepage/referral/${refCode}` : ""}
                    readOnly
                    style={{width: '100%'}}
                  />
                  <CopyToClipboard text={`https://invite.crypterium.game/salepage/referral/${refCode}`}>
                    <button type="button" className="btn-copycode"><i className="icon-fl-file-1"></i></button>
                  </CopyToClipboard>
                  
                </form>
                <p
                  className="content"
                  style={{ marginTop: "20px", fontSize: "2rem" }}
                >
                  Total Reward:{" "}
                  {rewards && rewards.paid_reward + rewards.pending_reward}
                </p>
                <p
                  className="content"
                  style={{ marginTop: "20px", fontSize: "2rem" }}
                >
                  Paid Reward: {rewards && rewards.paid_reward}
                </p>
                <p className="content" style={{ fontSize: "2rem" }}>
                  Pending Reward: {rewards && rewards.pending_reward}
                </p>
              </div>
            </div>
            {refCode && (
              <div
                className="widget widget-social style-2"
                style={{ alignItems: "center" }}
              >
                <h4 className="title-widget">Share:</h4>
                <LineShareButton
                  url={`https://invite.crypterium.game/salepage/referral/${refCode}`}
                >
                  <LineIcon round={true} size={48} />
                </LineShareButton>
                <FacebookShareButton
                  url={`https://invite.crypterium.game/salepage/referral/${refCode}`}
                >
                  <FacebookIcon round={true} size={48} />
                </FacebookShareButton>
                <TwitterShareButton
                  url={`https://invite.crypterium.game/salepage/referral/${refCode}`}
                >
                  <TwitterIcon round={true} size={48} />
                </TwitterShareButton>
                <TelegramShareButton
                  url={`https://invite.crypterium.game/salepage/referral/${refCode}`}
                >
                  <TelegramIcon round={true} size={48} />
                </TelegramShareButton>
              </div>
            )}
            {rewards && (
              <div style={{ marginTop: "10px" }}>
                <h3>Total Member {rewards.downline_1_count + rewards.downline_2_count + rewards.downline_3_count + rewards.downline_4_count + rewards.downline_5_count} users</h3>
                <div
                  style={{
                    display: "flex",
                    marginTop: "20px",
                    flexWrap: "wrap",
                  }}
                >
                  <div className="inner boder pad-r-50">
                    <h6 className="des">Tier 1</h6>
                    <p>{rewards.downline_1_count} users</p>
                  </div>
                  <div className="inner boder pad-r-50">
                    <h6 className="des">Tier 2</h6>
                    <p>{rewards.downline_2_count} users</p>
                  </div>
                  <div className="inner boder pad-r-50">
                    <h6 className="des">Tier 3</h6>
                    <p>{rewards.downline_3_count} users</p>
                  </div>
                  <div className="inner boder pad-r-50">
                    <h6 className="des">Tier 4</h6>
                    <p>{rewards.downline_4_count} users</p>
                  </div>
                  <div className="inner boder pad-r-50">
                    <h6 className="des">Tier 5</h6>
                    <p>{rewards.downline_5_count} users</p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
      {downlines.map((d, index) => {
        return (
          <section
            key={index}
            className="tf-section tf-rank"
            style={{ padding: "20px 0" }}
          >
            <div className="container-fluid">
              <h2>Tier {index + 1}</h2>
              <h4 style={{ marginTop: "10px" }}>
                Total Reward: <span>{d.downline_reward}</span>
              </h4>
              <h4 style={{ marginTop: "10px" }}>
                Pending Reward: <span>{d.downline_pending_reward}</span>
              </h4>
              <div className="row" style={{ marginTop: "0px" }}>
                <div className="col-md-12">
                  <div className="table-ranking">
                    <div className="flex th-title">
                      <div className="column1">
                        <h3></h3>
                      </div>
                      <div className="column">
                        <h3></h3>
                      </div>
                    </div>
                    {d.downline_member.map((item, index) => (
                      <div key={index} className="fl-item2">
                        <div className="item flex">
                          <div className="infor-item flex column1">
                            <div className="content-collection pad-t-4">
                              <h5 className="title">{item.wallet_id}</h5>
                            </div>
                          </div>
                          <div className="column">
                            <span>{item.created_at}</span>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </section>
        );
      })}

      <CardModal show={modalShow} onHide={() => setModalShow(false)} />
    </div>
  );
};

export default Refer;
