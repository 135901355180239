import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import { MoralisProvider } from "react-moralis";

ReactDOM.render(
  <MoralisProvider
    appId={process.env.REACT_APP_MORALIS_APP_ID}
    serverUrl={process.env.REACT_APP_MORALIS_SERVER_URL}
  >
    <BrowserRouter>
      <ScrollToTop />
      <App />
    </BrowserRouter>
  </MoralisProvider>,
  document.getElementById("root")
);
